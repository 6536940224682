<template>
  <div class="wellcome">
    <section class="wellcome__section">
      <b-link  class="wellcome__logo"  @click="() => $router.push({ name: 'site-home' })"><div/> </b-link>
      <header class="wellcome__heading">
        <h1 class="wellcome__title">Olá,</h1>
        <h1 class="wellcome__title">Bem vindo a Divvy!</h1>
        <p class="wellcome__subtitle">
          Como você deseja entrar na nossa plataforma?
        </p>

        <main class="wellcome__main">
          <div class="wellcome__investor button">
            <router-link :to="{ name: 'login' }"> Sou Investidor </router-link>
          </div>
          <div class="wellcome__company button">
            <router-link :to="{ name: 'company-login' }"> Sou Construtora </router-link>
          </div>
          <div class="wellcome__broker button">
            <router-link :to="{ name: 'broker-login' }"> Sou Corretor </router-link>
          </div>
          <div class="wellcome__back button">
            <router-link :to="{ name: 'site-home' }"> Voltar </router-link>
          </div>
        </main>
      </header>
    </section>
    <background-card />
  </div>
</template>

<script>
import BackgroundCard from './template/BackgroundCard.vue'

import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BackgroundCard,
    BLink,
  },
}
</script>

<style lang="scss">
@import './sass/wellcome.scss';
</style>
